/**
 * Carousel
 *
 * This component requires jQuery and Slick to function.
 * You can require it on the page using <div data-component="carousel"></div>
 */

import $ from 'jquery';
import 'slick-carousel';

$(function () {
    $('[data-component="carousel"]').each(function () {
        var $holder = $(this);

        let variableWidth = $holder.attr('data-variable-width');

        if ( variableWidth === null ) {
            variableWidth = true;
        } else {
            variableWidth = variableWidth === 'true';
        }

        $holder.slick(
            {
                prevArrow: '<button class="carousel__prev slick-prev"></button>',
                nextArrow: '<button class="carousel__next slick-next"></button>',
                dots: true,
                rows: 0,
                dotsClass: 'carousel__pager',
                slidesToShow: 3,
                infinite: true,
                variableWidth: variableWidth
            }
        );
    });
});